
@import "./variables.scss";
@import "./fonts.scss";
@import "./mixins.scss";
@import "./animations.scss";

a {
    text-decoration: none;
    color: $color1;

    &:visited {
        color: $color1;
    }
}

ul,
li,
body {
    margin: 0;
    padding: 0;
    font-family: "NotoSans-Regular";
}

.block-pc{
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    text-align: center;
    width: 100%;
    height: 100vh;
    padding: 1em;
    img{
        width: 100px;
    }
}


.error{
    color:rgb(218, 63, 63);
}

.prompt {
    color: #ccc;
    margin: -14px 0 0 0;
}

body {
    display: flex;
    justify-content: center;
    color: $color1;

    .global-container {
        position: relative;
        overflow: hidden;
        overflow-y: auto;
        height: 100vh;
        width: 100vw;
        background-color: #fff;
        box-sizing: border-box;

        &::-webkit-scrollbar {
            width: 0px;
        }

        &::-webkit-scrollbar-track {
            background-color: #e4e4e4;
            border-radius: 100px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #aaa;
            border-radius: 100px;
        }

        .save_message {
            @include default-button();
            background-color: #4c4;
            cursor: auto;
        }



        .back-block {
            display: flex;
            align-items: center;
            padding: 0 .5em;
            cursor: pointer;

            p {
                padding-left: .5em;
                font-size: 16px;
            }
        }


        .blocks {
            width: $screen_width;
            padding: .5em;
            box-sizing: border-box;
            &.ctg-page-blocks {
                display: grid;
                flex-wrap: wrap;
                justify-content: center;
                box-sizing: border-box;
                justify-items: center;
                gap: 1em;
                grid-template-columns: 1fr 1fr;

                .swiper-wrapper{
                    .block {
                        box-sizing: border-box;
                        width: 230px;
    
                        a {
                            justify-items: center;
                            justify-content: center;
                            box-sizing: border-box;
    
                            img {
                                box-sizing: border-box;
                                width: 230px;
                            }
                        }
                    }
                }
            }

            .block {
                display: grid;
                justify-items: center;
                text-align: center;
                box-sizing: border-box;

                p {
                    margin: 0;
                }

                .price {
                    font-size: 18px;
                    font-weight: bold;
                    padding: .5em;
                    box-sizing: border-box;
                }

                .discription {}

                img {
                    border-radius: 10px;
                    object-fit: cover;
                    width: calc(50vw - 34px);
                    height: 220px;
                }
            }
        }

        main {
            display: grid;
            box-sizing: border-box;
            padding-bottom: 4em;

            .empty-text-home{
                display: grid;
                height: 100%;
                align-content: center;
                justify-items: center;
                text-align: center;
                font-size: 24px;
                img{
                    width: 50px;
                }
            }

        }

        .thank-page{
            display: grid;
            height: calc(100vh - 100px);
            align-items: center;
            align-content: center;
            justify-content: center;
            justify-items: center;
            img{
                width: 50px;
                height: 50px;
            }
            .back-to-main{
                @include default-button();
                width: fit-content;
            }
            .loading{
                animation: .5s infinite loading linear;
            }
        }





        .contacts-container {
            h1 {
                text-align: center;
            }

            .map_widget {}

            .tg-link {
                display: flex;
                gap: .5em;
                align-items: center;
                width: fit-content;
                p{
                    margin: 0;
                }
            }
            .contact-content{
                padding: 0 1em;
                margin-top: 1em;
                &__wrapper{
                    padding: .5em 0;
                }
            }
        }
    }
}

@media (min-device-width: 480px) {

    .global-container {
        width: 480px !important;
        .blocks .swiper-slide.block img {
            width: 230px;
        }
        .footer-block {
            width: 480px !important;
        }
    }
}

@media (max-device-width: 480px) and (min-device-width: 400px) {
    .global-container {
        width: 100vw;

        .blocks .swiper-slide.block img {
            height: 180px !important;
        }
    }
}

@media (max-device-width: 400px) and (min-device-width: 330px) {
    .global-container {

        .products-page-container .products-control h1 {
            font-size: 22px !important;
        }

        .blocks .swiper-slide.block {
            p {
                font-size: 16px !important;
            }
            img {
                width: 55vw !important;
                height: 180px !important;
            }
        }


    }
}

@media (max-device-width: 330px) and (min-device-width: 276px) {
    .global-container {
        width: 100vw;

        .products-page-container .products-control h1 {
            font-size: 22px !important;
        }


        .blocks .swiper-slide.block {
            p {
                font-size: 14px !important;
            }
            img {
                width: 55vw !important;
                height: 160px !important;
            }
        }

        .img-album .swiper-slide {
            height: 350px !important;
        }


    }
}

@media (max-device-width: 276px) {
    .global-container {
        .img-album .swiper-slide {
            height: 300px !important;
        }

        .blocks .swiper-slide.block {
            p {
                font-size: 14px !important;
            }
            img {
                width: 55vw !important;
                height: 140px !important;
            }
        }

    }
}

