@import "../../../styles/fonts.scss";

.admins-page-container{
    .admins-page-content{
        padding: 1em;
        .admin-heading{
            display: flex;
            justify-content: space-between;
            align-items: center;
            h1{
                font-size: 1.5em;
            }
            .admin-add-button{
                font-family: "NotoSans-Regular";
                background-color: #444;
                color: #fff;
                border: none;
                padding: .2em .4em;
                font-size: 14px;
                border-radius: 5px;
                svg{display: block}
            }
        }
        .admins-list{
            display: grid;
            gap: .7em;
            .admins-item{
                display: flex;
                gap: 1em;
                padding: .5em 1em;
                border-radius: 5px;
                flex-wrap: wrap;
                align-items: center;
                color: #fff;
                background-color: #444444;
                p{margin: 0}
                .admin-name-id{
                    .admin-id{
                        font-size: 10px;
                        font-style: italic;
                        color: #ccc;
                    }
                    .admin-name{
                    }
                }
                .admin-shop{
                    h5{
                        font-size: 12px;
                        font-weight: 100;
                        font-style: italic;
                        color: #ccc;
                        margin: 0;
                    }
                }
                .admin-delete{
                    margin-left: auto;
                    min-width: 20px;
                }
            }
        }
    }
}