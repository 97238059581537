

.ctg-page-container {
    padding-bottom: 2em;

    .heading {
        padding: 1em;
    }
}



@media (max-device-width: 480px) and (min-device-width: 400px) {
    .ctg-page-blocks.blocks .block img {
        width: 40vw !important;
        height: 180px !important;
    }
}
@media (max-device-width: 400px) and (min-device-width: 330px) {
    .ctg-page-container {
        .heading h1 {
            margin: .5em;
            font-size: 28px;
        }

        .ctg-page-blocks.blocks {
            column-gap: 0 !important;
            .block {
                p {
                    font-size: 16px !important;
                }
                img {
                    width: 42vw !important;
                    height: 180px !important;
                }
            }
        }
    }
}

@media (max-device-width: 330px) and (min-device-width: 276px) {
    .ctg-page-container {
        .heading h1 {
            margin: .3em;
            font-size: 24px;
        }

        .ctg-page-blocks.blocks {
            column-gap: 0 !important;
            .block {
                p {
                    font-size: 14px !important;
                }

                img {
                    width: 45vw !important;
                    height: 150px !important;
                }
            }
        }
    }
}

@media (max-device-width: 276px) {
    .ctg-page-container {
        .heading h1 {
           margin: .3em;
           font-size: 24px;
        }

        .ctg-page-blocks.blocks {
            column-gap: 0 !important;
            .block {
                p {
                    font-size: 14px !important;
                }

                img {
                    width: 40vw !important;
                    height: 120px !important;
                }
            }
        }
    }
}