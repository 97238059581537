@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.admin-panel-container {
    display: grid;
    height: calc(100% - 73px);
    align-content: start;
    align-items: start;
    position: relative;

    h1 {
        text-align: center;
        margin: 0;
    }

    .admin-panel-header {
        margin-top: 2em;
        padding: 2em;

        .shop-select {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .main-block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        width: 100%;
        padding: 1em;
        box-sizing: border-box;

        .block {
            height: 80px;
            background-color: $color1;
            color: #fff;
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            cursor: pointer;
            border-radius: 10px;
        }

    }
}

.admin-block-container {
    
    .heading {
        text-align: center;
    }


    .categories-block {
        display: flex;
        gap: .5em;
        flex-wrap: wrap;
        align-items: center;

        .selection-block {
            display: flex;
            gap: .5em;
            align-items: center;

            .selection-input {
                height: 33px;
            }

            .MuiSelect-select {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }

            .add-ctg-block {
                display: flex;
                gap: .5em;
            }
        }

        .category-item {
            display: flex;
            gap: .5em;
            padding: .3em 1em;
            border-radius: 10px;
            background-color: #ccc;
            align-items: center;

            p {
                margin: 0;
            }

            svg {
                cursor: pointer
            }

            &.but {
                cursor: pointer
            }
        }
    }

    &.categories-page-container {
        display: grid;
        padding-bottom: 1em;

        .categories-list {
            display: grid;
            gap: 1em;
            padding: 1em;

            .categories-item {
                border-radius: 10px;
                align-items: center;
                box-sizing: border-box;
                justify-content: space-between;
                display: flex;
                width: 100%;
                gap: 1em;
                padding: .5em 1em;
                background-color: $color1;
                color: #fff;

                input {
                    color: #fff !important;
                    font-family: "Roboto-Regular";
                }

                .item-control {
                    display: flex;
                    gap: 1em;
                }

                .item-name {
                    flex-grow: 1;
                }

                svg {
                    cursor: pointer;
                }
            }

            .categories-control {
                justify-self: center;
                width: 100%;
                height: 50px;
                background-color: #777;
                border-radius: 10px;
                cursor: pointer;
            }

            .add-block {
                display: flex;
                align-items: center;
                gap: 1em;
                flex-grow: 1;

                .name-ctg {
                    flex-grow: 1;
                }

                svg {
                    width: 50px;
                    height: 50px;
                    cursor: pointer;
                }

                .add-ctg {
                    background-color: $color1;
                    border-radius: 10px;
                }
            }
        }
    }

    &.products-page-container {

        .products-control {
            margin: 0 1em;

            .heading {}

            display: flex;
            justify-content: space-between;
            align-items: center;

            .add_but {
                @include default-button();
            }
        }

        .products-list {
            display: grid;
            align-content: start;
            box-sizing: border-box;
            gap: 1em;
            margin: 1em;

            .product-item {
                width: 100%;
                padding: 1em;
                box-sizing: border-box;
                border-radius: 10px;
                background-color: #eee;

                &-name,
                &-price {
                    display: flex;
                    gap: 1em;
                    margin: .5em 0;
                    justify-content: space-between;
                    align-items: center;

                    h3,
                    p {
                        margin: 0;
                    }

                    .field {
                        width: 100%;
                        height: 40px;

                        .MuiInputBase-input {
                            padding-top: .5em;
                            padding-bottom: .5em;
                        }
                    }

                    .admin-but {
                        padding: .2em;
                        min-width: 20px;
                        max-width: 20px;
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                    }
                }
                


                .album {
                    width: 250px;
                    overflow-x: auto;
                    display: flex;
                    align-items: flex-end;
                    box-sizing: border-box;
                    padding: .5em;
                    gap: .5em;

                    &-item {
                        width: 80px;
                        min-width: 80px;
                        height: 80px;
                        display: flex;

                        &.select-item {
                            position: relative;

                            &::after {
                                content: "";
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                background-color: #ff06;
                                border-radius: 4px;
                            }
                        }

                        img {
                            @include noselect();
                            width: 80px;
                            min-width: 80px;
                            height: 80px;
                            object-fit: cover;
                            border-radius: 4px;
                            box-shadow: 2px 2px 4px -1px #666;

                            &:nth-child(1) {
                                width: 80px;
                                min-width: 80px;
                                height: 80px;
                            }
                        }

                    }

                }

                .img-control {
                    display: flex;
                    margin-top: 1em;
                    justify-content: space-between;

                    .control-item {
                        display: flex;
                        padding: .2em;
                        width: 25px;
                        height: 25px;
                        display: flex;
                        border-radius: 4px;
                        background-color: #aaa;
                        cursor: pointer;
                        justify-items: center;
                        align-items: center;

                        label {
                            display: flex;
                        }

                        svg {
                            width: 100%;
                            height: 100%;
                        }

                        &.file-upload-but {
                            input {
                                display: none
                            }
                        }

                        &.delete-img-toggle {
                            background-color: rgb(218, 63, 63);
                        }

                    }

                    .control-block {
                        display: flex;
                        gap: .5em;
                    }
                }

                .categories-block-heading {
                    margin: 1em 0 .5em 0;
                }

                .product-item-control{
                    margin-top: 1em;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 1em;
                    .delete-but {
                        @include default-button();
                        width: fit-content;
                        background-color: #cc2727;
                    }
                    .show-product-button{
                        display: flex;
                        svg{
                            display: flex;
                        }
                    }
                }

            }
        }
    }

    &.add-product-page-container {
        .block-inputs {
            display: grid;
            gap: 1em;
            align-items: start;
            padding-bottom: 1em;

            .categories-block-heading {
                margin: 1em 0 0 0;
            }

            .input {
                width: 100%;
            }

            .load-images-container {
                .images-block {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1em;
                    margin: .5em;

                    .image-item {
                        img {
                            width: 80px;
                            height: 80px;
                            object-fit: cover;
                        }
                    }
                }
            }

            .add-product-but {
                @include default-button();
                margin: 1em 0
            }
        }

        .load-images-but {
            @include default-button();
            display: grid;

            input {
                display: none;
            }
        }
    }

    &.contacts-page-container {
        .contacts-content {
            display: grid;
            padding: 0 1em 2em 1em;
            gap: 1em;

            .but-send {
                @include default-button();
                position: relative;
                height: 45px;
            }
        }
    }

    &.orders-page-container {
        .order-content {
            h1 {
                text-align: center;
            }

            .order-item {
                
                background-color: #ccc;
                padding: 1em;
                margin: 1em;
                border-radius: 10px;

                &.close-item {
                    opacity: .6;

                    .close-tag {
                        margin: 0;
                        color: #cc2727;
                    }
                }

                .heading-item {
                    display: flex;
                    align-items: center;
                    gap: .5em;

                }

                .s1 {
                    color: #944
                }

                .s2 {
                    color: #994
                }

                .key {
                    color: #000;
                }

                p {
                    margin: .5em;
                }

                .order-completed-but {
                    @include default-button();
                    position: relative;
                    margin-top: 1em;

                    &.loading-but {
                        padding: 1.4em;
                    }
                }
            }
            .yandex-order{
                position: relative;
                border-top: 15px solid #F2D700;
                box-sizing: border-box;
                &::before{
                    content: "";
                    position: absolute;
                    top: -15px;
                    left: 0;
                    width: 15px;
                    height: 15px;
                    border-top-left-radius: 10px;
                    background-color: #000000;
                }
                .order-status-control-but{
                    @include default-button();
                    background-color: #F2D700;
                    margin: .5em 0;
                    color: #000;
                }
                .order-cancel-but{
                    @include default-button();
                    background-color: #444;
                    margin-top: .5em;
                }
            }
            .order-switch-platform{
                padding: 0 1em 1em 1em;
                display: flex;
                gap: 1em;
                &-item{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    p{
                        margin: 0;
                        width: 70px;
                    }
                    
                    &-button{
                        @include default-button();
                        border: none;
                        flex: 1;
                        transition: color .2s, background-color .2s;
                        &.active{
                            background-color: #ccc;
                            color: #444
                        }
                    }
                }
            }
            .orders-pagination{
                padding: 0 1em;
                display: flex;
                gap: .5em;
                &-item{
                    display: block;
                    text-align: center;
                    background-color: #cccccc;
                    padding: .3em .5em;
                    width: 20px;
                    font-size: 14px;
                    font-weight: bold;
                    border-radius: 10px;
                }
            }
            
            .dialog-select-product{
                position: fixed;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                max-width: 500px;
                padding: 1em;
                box-sizing: border-box;
                height: 100%;
                background-color: #fff;
                .dialog-select-product-content{
                    &-list{
                        display: grid;
                        overflow-y: auto;
                        height: calc(100vh - 140px);
                        gap: 1em;
                        box-sizing: border-box;
                    }
                    &-item{
                        display: flex;
                        align-items: center;
                        gap: 1em;
                        img{
                            width: 50px;
                            height: 50px;
                        }
                        p{
                            font-size: 14px;
                        }
                        .item-name{
                            width: 50%;
                        }
                        .item-price{
                            text-wrap: nowrap;
                        }
                        .item-quantity{
                            display: grid;
                            gap: .2em;
                            .item-input{
                                width: 100%;
                                box-sizing: border-box;
                                outline: none;
                            }
                            .item-buttons{
                                width: 100%;
                                display: flex;
                                gap: .2em;
                                .item-button{
                                    width: 100%;
                                    background-color: #444444;
                                    color: #fff;
                                    border: none;
                                }
                            }
                        }
                    }
                    &-button{
                        @include default-button();
                        width: 100%;
                        border: none;
                    }
                }
                
                &-cross{
                    position: absolute;
                    top: 1em;
                    right: 1em;
                    width: 20px;
                    height: 20px;
                }
                &-line{
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: #444444;
                }
                &-line:nth-child(1){
                    transform: translateY(10px) rotate(45deg);
                }
                &-line:nth-child(2){
                    transform: translateY(10px) rotate(-45deg);
                }
            }
        }
    }

    
    .product-item-amount {
        position: relative;
        display: flex;
        width: fit-content;
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        .field-number{
            width: 100px;
            margin: 0;
            padding: .5em;
            outline: none;
            border: 0;
            border-bottom: 1px solid #444444;
            box-sizing: border-box;
            color: #444444;
        }
        span{
            position: absolute;
            display: grid;
            background-color: #ffffff;
            color: #000;
            left: 68px;
            height: 29px;
            align-items: center;
            padding: 0 5px;
            bottom: 1px;
            font-size: 14px;
        }
        .buttons{
            display: flex;
            gap: 1px;
            z-index: 100;
            button{
                padding: 0 .5em;
                line-height: 0;
                border: 0;
                font-size: 16px;
                width: 25px;
                height: 30px;
                background-color: #444444;
                color: #ffffff;
            }
        }
    }
    .product-list-header{
        display: flex;
        align-items: center;
        gap: 1em;
        .product-item-button{
            background-color: #444;
            color: #ffffff;
            border: none;
            padding: .2em 1em;
            border-radius: 5px;
            cursor: pointer;
        }
    }

}

.shops-page-container{
    padding-bottom: 2em;
    .shops-content{
        display: grid;
        gap: 1em;

        h1{
            padding: 0 1em;
        }
        
        .copy-notification{
            position: absolute;
            display: grid;
            justify-content: center;
            align-content: center;
            font-size: 12px;
            bottom: 15%;
            left: calc(50% - 60px);
            background-color: #444c;
            opacity: 0;
            transition: .3s;
            color: #fff;
            width: 120px;
            height: 40px;
            border-radius: 10px;
            z-index: 999;
            &.active{
                opacity: 1;
            }
        }
        .admin-shops-item{
            position: relative;
            display: grid;
            box-sizing: border-box;
            background-color: #f1f1f1;
            border-radius: 10px;
            box-sizing: border-box;
            padding: .5em 1em;
            margin: 0 1em;
            .admin-shops-item-wrapper{
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                gap: .5em;
                .item-name{
                    display: flex;
                    width: 100%;
                    align-items: center;
                    gap: .5em;
                    p{
                        font-size: 14px;
                        font-weight: bold;
                    }
                    .item-edit-button{
                        height: fit-content;
                        cursor: pointer;
                        svg{
                            display: block;
                        }
                    }
                }
                .item-status{
                    cursor: pointer;
                }
            }
            .item-link{
                display: flex;
                gap: .5em;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                background-color: #ffffff;
                padding: .5em;
                border-radius: 10px;
                p{
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin: 0;
                    font-size: 14px;
                    font-style: italic;
                }
            }
        }
        .admin-shops-new-shop-button{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #444444;
            border-radius: 10px;
            padding: .5em 1em;
            margin: 0 1em;
            cursor: pointer;
        }
    }
}


@media (max-device-width: 480px) and (min-device-width: 400px) {
    .global-container {
        .admin-panel-container {
            h1 {
                font-size: 24px;
                padding: 1em .5em .5em .5em !important;
            }

            .main-block .block {
                height: 80px !important;
                font-size: 22px !important;
            }
        }
    }
}


@media (max-device-width: 400px) and (min-device-width: 330px) {
    .global-container {

        .admin-panel-container {
            h1 {
                font-size: 24px;
                padding: 1em .5em .5em .5em !important;
            }

            .main-block .block {
                height: 80px !important;
                font-size: 22px !important;
            }
        }
    }
}

@media (max-device-width: 330px) and (min-device-width: 276px) {
    .global-container {
        .admin-panel-container {
            h1 {
                font-size: 24px;
                padding: 1em .5em .5em .5em !important;
            }

            .main-block .block {
                height: 80px !important;
                font-size: 20px !important;
            }
        }
    }
}

@media (max-device-width: 276px) {
    .global-container {
        .admin-panel-container {
            h1 {
                font-size: 24px;
                padding: 1em .5em .5em .5em !important;
            }

            .main-block .block {
                height: 80px !important;
                font-size: 18px !important;
            }
        }
    }
}