
@font-face {
    font-family: "Roboto-Medium";
    src: url("../assets/fonts/Roboto-Medium.ttf");
}

@font-face {
    font-family: "Roboto-Regular";
    src: url("../assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: "NotoSans-Regular";
    src: url("../assets/fonts/NotoSans-Regular.ttf");
}
