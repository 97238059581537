@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.header {
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 1em 2em;
    box-sizing: border-box;
    width: 100%;
    color: #fff;
    background-color: $color1;
    
    .shop-select{
        cursor: pointer;
    }

    .menu-toggle {
        position: relative;
        width: 30px;
        height: 20px;
        cursor: pointer;

        &.active {
            .line:nth-child(1) {
                transform: rotate(45deg);
                top: 50%
            }

            .line:nth-child(2) {
                opacity: 0;
            }

            .line:nth-child(3) {
                transform: rotate(-45deg);
                top: 50%
            }
        }

        .line {
            position: absolute;
            background-color: #fff;
            width: 100%;
            height: 2px;
            transition: .1s;

            &:nth-child(1) {
                top: 0px
            }

            &:nth-child(2) {
                top: 50%
            }

            &:nth-child(3) {
                top: 100%
            }
        }
    }

    .logo {
        @include noselect();
        font-size: 20px;
        flex: 1;
        .shop-select{
            display: flex;
            align-items: center;
            gap: .3em;
            p{
                color: #e1e1e1;
                margin: 0;
                font-size: 14px;
            }
        }
        a {
            color: #fff
        }
    }


    .basket {
        position: relative;

        svg {}

        .basket-amount {
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            background-color: #ff9720;
            border-radius: 100%;
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            color: #fff;
        }
    }

    .user_but {
        cursor: pointer;
    }
}


@media (max-device-width: 400px) and (min-device-width: 330px) {
    .global-container {

        .header {
            .logo {
                font-size: 16px !important;
            }

            svg {
                width: 25px !important;
                height: 25px !important;
            }

            .menu-toggle {
                width: 22px !important;
                height: 14px !important;
            }

            .basket-amount {
                width: 15px !important;
                height: 15px !important;
            }
        }
    }
}

@media (max-device-width: 330px) and (min-device-width: 276px) {
    .global-container {
        width: 100vw;

        .header {
            .logo {
                font-size: 14px !important;
            }

            svg {
                width: 25px !important;
                height: 25px !important;
            }

            .menu-toggle {
                width: 22px !important;
                height: 14px !important;
            }

            .basket-amount {
                width: 15px !important;
                height: 15px !important;
            }
        }
    }
}

@media (max-device-width: 276px) {
    .global-container {
        .header {
            .logo {
                font-size: 12px !important;
            }

            .menu-toggle {
                width: 22px !important;
                height: 14px !important;
            }

            .basket-amount {
                width: 15px !important;
                height: 15px !important;
            }
        }
    }
}