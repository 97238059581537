@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.product-page-container {
    padding-bottom: 2em;

    .img-album {
        background-color: #8881;

        .swiper-pagination-bullet-active {
            background-color: $color1;
        }

        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 400px;
        }

        img {
            max-width: 100%;
            max-height: 400px;
        }
    }

    .content-block {
        padding: 0 .5em;

        .name-product {
            font-size: 22px;
            font-weight: bold;
        }

        .price-product {
            font-size: 26px;
            font-weight: bold;
        }

        .to-basket-button {
            @include noselect();
            @include default-button();
            width: 100%;
            border: 3px solid $color1;
            transition: .3s;

            &.in-basket {
                display: grid;
                background-color: #4440;
                color: $color1;
                font-weight: bold;
            }
        }
    }
}

@media (max-device-width: 400px) and (min-device-width: 330px) {
    .product-page-container {
        .content-block {
            .name-product {
                font-size: 20px !important
            }

            .price-product {
                font-size: 22px !important
            }
        }
    }
}

@media (max-device-width: 330px) and (min-device-width: 276px) {
    .product-page-container .content-block {
        .name-product {
            font-size: 16px !important
        }

        .price-product {
            font-size: 20px !important
        }
    }
}

@media (max-device-width: 276px) {
    
    .products-page-container .products-control h1 {
        font-size: 22px !important;
    }
    .product-page-container .content-block {
        .name-product {
            font-size: 16px !important
        }

        .price-product {
            font-size: 20px !important
        }
    }
}