@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.build-order-container {
    padding-bottom: 2em;

    .deliver-block {
        display: flex;
        padding: 1em 1em 0 1em;
        justify-content: center;
        gap: 1em;

        .select-item {
            padding: 1.5em;
            flex: 50%;
            cursor: pointer;
            background-color: #ccc8;
            border-radius: 10px;
            color: $color1;
            transition: .1s;

            p {
                margin: 0;
                font-weight: bold;
                text-align: center;
                font-size: 20px;
            }

            &.active {
                color: #fff;
                background-color: $color1;
            }
        }
    }

    .order-info-block {
        padding: 0 1em;

        .date-order {
            width: 100%;
            box-sizing: border-box;

            input {
                font-family: "Roboto-Regular";
                font-size: 16px;
            }
        }

        .time-order {
            margin-top: 1em;

            .MuiSelect-select {
                font-family: "Roboto-Regular";
                font-size: 16px;
            }
        }

        .address-order {
            margin-top: 1em;
        }

        .input-type {

            label {
                font-family: "Roboto-Regular";
            }
        }



        p.heading {
            margin: 0;
            margin-top: .5em;
            padding: .3em 0;
            font-size: 16px;

            &.long {
                margin-top: .5em;
                font-size: 24px;
            }
        }

        .number-order {
            width: 100%;
            margin-top: 1em;

            input {
                width: 100%;
                height: 60px;
                font-family: "Roboto-Regular";
                font-size: 18px;
            }
        }

        .checkbox-container {
            margin-top: 1em;

            .checkbox-label {
                span {
                    font-size: 16px;
                }
            }

            .checkbox-hide-block {
                background-color: #ccc3;
                height: 0px;
                overflow: hidden;
                transition: .2s;

                &.open {
                    padding: 1em
                }

                &.hide-block-other {
                    &.open {
                        height: 155px
                    }
                }

                &.hide-block-postcard {
                    &.open {
                        height: 55px
                    }
                }
            }

            .input-type {
                margin-top: 0;
            }
        }


    }

    .points-info-block{
        margin: 0 1em;
        border-radius: 10px;
        color: #444;
        h3{
            text-align: center;
        }
        .points-select-act{
            display: flex;
            gap: .5em;
            background-color: #ffffff;
            border-radius: 15px;
            .points-select-button{
                padding: .5em;
                text-align: center;
                flex: 50%;
                cursor: pointer;
                background-color: #ccc;
                border-radius: 10px;
                box-sizing: border-box;
                color: $color1;
                transition: .1s;
                &.active {
                    color: #fff;
                    background-color: $color1;
                }
            }
        }
        .points-block-header{
            display: flex;
            gap: .5em;
            align-items: center;
            .points-button{
                cursor: pointer;
            }
        }
    }

    .finish-info-block{
        margin: 1em 1em 0 1em;
        .line{
            display: flex;
            justify-content: space-between;
            &.points_green{color: #4c4}
            &.points_red{color: #c44}
        }
        p{
            margin: 0;
        }
    }

    .build-order-but {
        position: relative;
        @include default-button();
        margin: 1.5em 1em 1em 1em;
        .loader{
            padding: 11.2px;
        }
    }
}