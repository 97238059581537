
.dialog-select-shop-container{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #0005;
    z-index: 100;
    .dialog-select-shop-block{
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 1em;
        border-radius: .5em;
        transform: translate(-50%, -50%);
        width: 90%;
        box-sizing: border-box;
        height: fit-content;
        background-color: #fff;
        .dialog-select-shop-header{
            font-size: clamp(16px, 5vw, 22px);
            margin: 0;
            margin-bottom: .5em;
        }
        .dialog-select-shop-body{
            display: grid;
            .dialog-select-shop-body-item{
                display: flex;
                gap: .5em;
                align-items: center;
                font-size: clamp(11px, 3.5vw, 16px);
                cursor: pointer;
            }
        }
    }
}

.dialog-start-select-shop-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    .dialog-start-select-shop-block{
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 1em;
        border-radius: .5em;
        transform: translate(-50%, -50%);
        width: 90%;
        .dialog-start-select-shop-header{
            text-align: center;
            p{
                margin: 0;
            }
            .heading{
                font-size: clamp(24px, 7vw, 32px);
            }
            .subheading{
                margin-top: 1em;
                font-size: clamp(14px, 4vw, 18px);
            }
        }
        .dialog-start-select-shop-body{
            display: grid;
            margin-top: 2em;
            gap: 1em;
            &-item{
                display: flex;
                padding: 1em 1em;
                border-radius: 10px;
                cursor: pointer;
                font-size: clamp(12px, 4vw, 16px);
                gap: 1em;
                align-items: center;
                background-color: #f1f1f1;
                justify-content: space-between;
                p{
                    margin: 0;
                }
                svg{

                }
            }
        }
    }
}