@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.window-screen-info-points{
    @include window-screen-container();
    .window-screen-block{
        @include window-screen-content-block();
        .header-block{
            display: flex;
            justify-content: space-between;
            .info-button{
                cursor: pointer;
            }
        }
    }
}

.add-new-shop-container, .edit-new-shop-container{
    @include window-screen-container();
    .add-new-shop-block, .edit-new-shop-block{
        @include window-screen-content-block();
        width: 90vw;
        height: clamp(170px, 50vw, 200px);
        display: grid;
        gap: clamp(.5em, 1.5vw, 1em);
        .add-new-shop-header, .edit-new-shop-header{
            font-size: clamp(18px, 5vw, 24px);
            margin: 0;
        }
        .add-new-shop-input, .edit-new-shop-input{
            label, .MuiInputBase-root, fieldset{
                font-size: clamp(11px, 3.5vw, 16px);
            }
        }
        .add-new-shop-button, .edit-new-shop-button{
            @include default-button();
            align-self: end;
        }
    }
    .add-new-shop-block{
        height: clamp(350px, 100vw, 450px);
    }
}

.add-admin-block{
    height: 350px !important;
    .add-new-shop-button{
        align-self: end;
    }
    .select-shop-button{
        border: 1px solid #ccc;
        align-self: end;
        padding: .8em 1em;
        font-size: 16px;
        color: #444;
        justify-content: flex-start;
        text-transform: none;
    }
}