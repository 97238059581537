
@mixin noselect {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

@mixin default-button {
    box-sizing: border-box;
    border-radius: 10px;
    background-color: $color1;
    color: #fff;
    text-align: center;
    font-size: 16px;
    padding: .7em 1.5em;
    cursor: pointer;
}

@mixin window-screen-container {
    position: fixed;
    display: grid;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0006;
    z-index: 500;
}
@mixin window-screen-content-block {
    width: 250px;
    height: 300px;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 1em;
    background-color: #f1f1f1;
}