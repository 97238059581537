@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.user-info-container {
    display: grid;
    gap: 1em;
    padding: 1em;
    color: $color1;


    .save-but {
        @include default-button();
        position: relative;
    }

    .heading-block {
        display: grid;
        margin: 1em 0;
        gap: 1em;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: space-between;
        h1, h3{
            margin: 0
        }
        .user-info-points-block{
            grid-column: 1/3;
            display: flex;
            gap: .5em;
            .info-button{
                cursor: pointer;
            }
        }

        .admin-panel-button {
            @include default-button();

        }
    }

    .phone-number {
        width: 100%;

        input {
            width: 100%;
            padding: 1.5em 3em;
            font-size: 16px;
        }
    }

}

@media (max-device-width: 400px) and (min-device-width: 330px) {

    .user-info-container {
        .heading-block {
            h1 {
                font-size: 22px !important
            }
        }
    }
}

@media (max-device-width: 330px) and (min-device-width: 276px) {
    .user-info-container .heading-block h1 {
        font-size: 22px !important
    }
}

@media (max-device-width: 276px) {
    .user-info-container .heading-block h1 {
        font-size: 22px !important
    }
}