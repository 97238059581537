@import '../../styles/mixins.scss';

.active-menu {
    position: absolute;
    top: 72px;
    left: -310px;
    height: 200%;
    width: 300px;
    background-color: #ffffff;
    z-index: 10;
    transition: .1s;
    box-shadow: 3px 0px 10px -10px #000;

    &.active {
        left: 0;
    }

    .catalog-but {
        padding: 1em;
    }

    ul {
        list-style: none;

        li {
            @include noselect();
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            border-bottom: 1px solid #ddd;

            a {
                width: 100%;
                padding: 1em;
            }


            svg {
                transition: .2s;
            }

            svg.active {
                transform: rotate(180deg);
            }
        }

        .catalog {
            @include noselect();
            height: 0px;
            overflow: hidden;
            padding-left: 2em;
            transition: .1s;

        }
    }

}