@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.basket-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 75px);
    box-sizing: border-box;

    .content-block {
        width: 100%;
        padding-bottom: 50vh;

        .basket-item {
            display: flex;
            gap: 1em;
            padding: 1em;

            img {
                width: 100px;
                height: 100px;
                object-fit: cover;
            }

            .item-info {
                display: grid;
                width: 100%;
                flex-wrap: wrap;

                .item-name-delete{
                    display: flex;
                    .item-name {
                        flex: 90%;
                    }
    
                    .item-delete {
                        display: flex;
                        flex: 10%;
                        justify-content: flex-end;
                        align-items: center;
                        cursor: pointer;
                    }
                }
                .item-control-price{
                    display: flex;
                    .item-amount-control {
                        @include noselect();
                        display: flex;
                        flex: 30%;
                        align-items: center;
                        gap: 1em;
    
                        .control-button {
                            padding: .2em;
                            width: 20px;
                            height: 20px;
                            text-align: center;
                            font-weight: bold;
                            border-radius: 100%;
                            background-color: $color1;
                            cursor: pointer;
                        }
    
                        .item-amount {
                            font-size: 20px;
                        }
                    }
    
                    .item-price {
                        display: grid;
                        flex: 70%;
                        align-items: center;
                        justify-content: flex-end;
    
                        p {
                            margin: 0;
                            font-size: 24px;
                            font-weight: bold;
                        }
                    }
                }

            }
        }

        .footer-block {
            height: 170px;
            position: fixed;
            bottom: 1em;
            width: 100%;
            padding: 1em;
            box-sizing: border-box;
            background-color: #fff;

            .result-block {
                padding: .5em;
                text-align: right;

                p {
                    margin: 0
                }

                &-heading {}

                &-sum {
                    font-size: 34px;
                    font-weight: bold;
                }
            }

            .checkout-but {
                @include default-button();
                width: $screen_width;
                display: flex;
                justify-content: center;
                color: #fff;
            }
        }
    }

    .empty-basket {
        display: grid;
        justify-content: center;
        justify-items: center;
        align-items: start;
        align-content: center;
        height: calc(100% - 120px);

        &-text {
            font-size: 24px;
        }

        &-button {
            @include default-button();
            width: 100%;
        }
    }
}