@import "../../styles//animations.scss";

.global-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        animation: .5s infinite loading linear;
    }
}